import React from 'react';
// import ReactDOM from 'react-dom';
import {createRoot} from 'react-dom/client';

import './css/bootstrap.css';
import './index.css';
import App from './components/App/App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import './i18n';
import i18n from "i18next";
import {changeLanguage} from "./utils/language";

const root = createRoot(document.getElementById("root"));
if (!i18n.language) {
    changeLanguage('ar')
}

// if (i18n.language === 'ar') {
//     require('./css/bootstrap-rtl.css');
//     require('./css/style_ar.css');

// }

// else if (i18n.language == 'en'){
// require('./index.css');
// }
// else
//     require('./css/bootstrap.css');


root.render(
    <BrowserRouter>
        <App/>
    </BrowserRouter>,
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


